import React, { useState } from "react";
import "../dashboard.css";
import InfoCard from "../../../widgets/InfoCard";
import pvsIcon from "../../../assets/views.png";
import meetingIcon from "../../../assets/meetings.png";
import stallsIcon from "../../../assets/stalls.png";
import { calculateFilledPercentage, getUser } from "../../../utility/utility";
import connectionsIcon from "../../../assets/connections.png";
import bgBanner from "../../../assets/db_gif.gif";
import { CircularProgressbar } from "react-circular-progressbar";
import DashButton from "../../../widgets/DashButton";
import { Link, useNavigate } from "react-router-dom";
import MuPb from "../../../widgets/MuPb";
import CloseIcon from "@mui/icons-material/Close";
import useMatchedExhibitors from "./hooks/useMatchedExhibitors";
import { SimilarExhibitors } from "../exhibitor/Dashboard";
import { Box, CircularProgress, Drawer, IconButton } from "@mui/material";
import ExhibitorViewById from "../exhibitor/ExhibitorViewById";
import { USER, VISITOR, VISITORS } from "../../../constants/constants";
import useMeetings from "../exhibitor/hooks/useMeetings";
import MeetingCard from "../../../widgets/MeetingCard";
import { RecommendedExhibitors } from "../exhibitor/RecommendedExhibitors";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
const Dashboard = ({ user }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [currentExhibitor, setCurrentExhibitor] = useState();
  const {
    data: viewCount,
    isLoading: isViewCountLoading,
    isError: isViewCountError,
    error: viewCountError,
  } = useCommonGetInit(`${USER}/my-views-count?`);
  const { data, isLoading, isError, error } = useMatchedExhibitors(1);
  const {
    data: meetingsData,
    isLoading: isMloading,
    isError: isMerror,
    error: mError,
  } = useMeetings(user?.email);

  const PARENT_VIEW = "parent_view";
  const EXHIBITOR_VIEW = "exhibitor_view";
  const [currentView, setCurrentView] = useState(PARENT_VIEW);
  const [currentUser, setCurrentUser] = useState(null);
  const onExhibitorSelect = (cuser) => {
    setCurrentUser(cuser);
    setCurrentView(EXHIBITOR_VIEW);
  };

  const onSelect = (meetingItem) => {
    navigate(`/meeting/${meetingItem._id}`);
  };

  const onUpdateProfile = () => {
    navigate("/visitor/profile");
  };

  if (!user) {
    return <MuPb />;
  }

  return (
    <Box>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          width: "60%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: "60%",
            boxSizing: "border-box",
          },
        }}
      >
        <IconButton
          size="small"
          onClick={() => setIsOpen(false)}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 10,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        <Box>
          <ExhibitorViewById id={currentExhibitor?.exhibitorId} />
        </Box>
      </Drawer>
      <div className="rd-dash-content">
        <div className="dash-cards-65-35">
          <div
            className="dash-card-65"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="left-flex-right">
              <div
                className="left-item-flex"
                style={{
                  backgroundImage: `url(${bgBanner})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <div
                  style={{
                    fontWeight: "bold",
                    color: "white",
                    fontWeight: 500,
                    fontSize: "19px",
                  }}
                >
                  Welcome{" "}
                  <b>
                    {user && user.firstName} {user && user.lastName}
                  </b>
                  !
                </div>
                <div
                  style={{
                    color: "white",
                    fontSize: "14px",
                    marginTop: "10px",
                  }}
                >
                  Track your profile activity, leads and deals here.
                </div>
              </div>
              <div className="right-item">
                <div
                  style={{
                    width: 90,
                    height: 90,
                    display: "block",
                    margin: "auto",
                  }}
                >
                  <CircularProgressbar
                    strokeWidth={12}
                    value={calculateFilledPercentage(user)}
                    text={`${calculateFilledPercentage(user)}%`}
                  />
                </div>
                <div
                  style={{
                    color: "#666",
                    margin: "10px 12px",
                    fontWeight: 600,
                    fontSize: "14px",
                    textAlign: "center",
                  }}
                >
                  Complete your profile
                </div>
                <div style={{ flex: 1 }}></div>
                <DashButton
                  onClick={onUpdateProfile}
                  label="Update Profile"
                  color="rgb(47 146 188)"
                  hoverColor="rgb(21 80 106)"
                  bgColor="rgb(229 241 253)"
                  hoverBgColor="rgb(198 217 236)"
                  myStyle={{ width: "120px", margin: "auto" }}
                />
              </div>
            </div>
            <div
              style={{
                margin: "12px 0",
                fontSize: "17px",
                fontWeight: 600,
                color: "#004490",
              }}
            >
              Statistics
            </div>
            <div className="dash-info-cards">
              <InfoCard
                text="Stall Views"
                color="#ff981f"
                num={0}
                icon={stallsIcon}
              />
              <Link
                to={`/${getUser()?.role}/profile-viewers`}
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Profile Views"
                  color="#fb4d24"
                  num={viewCount ? viewCount.count : "..."}
                  icon={pvsIcon}
                />
              </Link>
              <Link
                to="all-meetings"
                component={"span"}
                style={{ textDecoration: "none", flex: "1 0 calc(25% - 20px)" }}
              >
                <InfoCard
                  text="Meeting Requests"
                  color="#004490"
                  num={meetingsData?.length || 0}
                  icon={meetingIcon}
                />
              </Link>
              <InfoCard
                text="Mutual Connections"
                color="#539506"
                num={0}
                icon={connectionsIcon}
              />
            </div>
          </div>
          <div className="dash-card-35">
            <div className="dash-list-cont">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Profile Activity
                </div>
                <div
                  style={{
                    color: "#004490",
                    cursor: "pointer",
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                >
                  See All
                </div>
              </div>
              <div
                style={{
                  color: "#ccc",
                  fontWeight: 600,
                  textAlign: "center",
                  margin: 16,
                }}
              >
                No Activity yet
              </div>
            </div>
          </div>
        </div>

        <div style={{ marginTop: "20px" }} className="dash-cards-65-35">
          <div className="dash-card-65">
            <div
              style={{
                display: "flex",
                flex: 1,
                borderRadius: "8px",
                backgroundColor: "white",
                gap: "0 16px",
              }}
            >
              <div style={{ flex: 1 }}>
                <div style={{ display: "flex", padding: "12px" }}>
                  <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                    Recommendations
                  </div>
                  <div
                    style={{
                      color: "#004490",
                      cursor: "pointer",
                      fontSize: "13px",
                      alignSelf: "center",
                    }}
                    onClick={() => navigate("/visitor/explore/ai-matching")}
                  >
                    See All
                  </div>
                </div>

                <RecommendedExhibitors />

                {/* {exhibitors.map((exh, i) => (
                <ExhibitorCard
                  key={i}
                  item={exh}
                  onSelectExhibitor={onExhibitorSelect}
                />
              ))} */}

                {/* {data?.length > 0 && (
                  <div
                    style={{
                      color: "#ccc",
                      fontWeight: 600,
                      textAlign: "center",
                      margin: 16,
                    }}
                  >
                    No Exhibitors yet
                  </div>
                )} */}

                {/* <SimilarExhibitors
                  type={VISITORS}
                  items={data}
                  isLoading={isLoading}
                  isError={isError}
                  onSelect={onClickItemView}
                /> */}
              </div>
            </div>
          </div>
          <div className="dash-card-35">
            <div className="meeting-items-card">
              <div style={{ display: "flex", padding: "12px" }}>
                <div style={{ color: "#004490", flex: 1, fontWeight: 600 }}>
                  Your Meetings
                </div>
                <div
                  style={{
                    color: "#004490",
                    cursor: "pointer",
                    fontSize: "13px",
                    alignSelf: "center",
                  }}
                  onClick={() => navigate(`all-meetings`)}
                >
                  See All
                </div>
              </div>
              <div
                style={{
                  flex: 1,
                  paddingLeft: "12px",
                  paddingRight: "12px",
                  paddingBottom: "12px",
                  overflowY: "auto",
                }}
              >
                {/* <div
                  style={{
                    color: "#ccc",
                    fontWeight: 600,
                    textAlign: "center",
                    margin: 16,
                  }}
                >
                  No Meetings Yet
                </div> */}
                {isMloading && <CircularProgress />}
                {meetingsData?.slice(0, 2).map((item, i) => (
                  <MeetingCard onClick={onSelect} meetingItem={item} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default Dashboard;
