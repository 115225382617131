import React, { useEffect, useState } from "react";
import "../dashboard.css";
import Footer from "../../footer/footer";

import { DEFAULT_DP, EXHIBITOR, USER } from "../../../constants/constants";
import { EXHIBITOR_MENU_LIST, deleteUser } from "../../../utility/utility";
import Dashboard from "./Dashboard";
import { useNavigate, useParams } from "react-router-dom";
import Settings from "./Settings";
import Services from "./oes/Services";
import Onsite from "./Onsite";
import Reports from "./Reports";
import ProfileDetails from "./profile/ProfileDetails";
import Explore from "./explore/Explore";
import NewDashHeader from "../header/NewDashHeader";
import SideMenuList from "./profile/SideMenuList";
import MuPb from "../../../widgets/MuPb";
import { Alert } from "@mui/material";
import ExhibitionCatalogue from "./oes/ExhibitionCatalogue";
import MySchedule from "./MySchedule";
import MobileSideMenuList from "./profile/MobileSideMenuList";
import GeneralView from "../../floorplan/view/GeneralView";
import HelpdeskPopup from "./HelpdeskPopup";
import useNotifications from "./hooks/useNotifications";
import AllMeetings from "./meeting/AllMeetings";
import ProfileViewByID from "./profile/ProfileViewByID";
import useCommonGetInit from "../../events/hooks/useCommonGetInit";
import ChatApp from "../../chatApp/ChatApp";
import MyFavorites from "../favorites/MyFavorites";
import ScheduleMeeting from "./meeting/ScheduleMeeting";
import ProductContainer from "./ProductContainer";
import ProfileViewers from "../favorites/ProfileViewers";
import MyStallViewers from "../favorites/MyStallViewers";
import MuiDashHeader from "../header/MuiDashHeader";
const ExhibitorDashboard = () => {
  const navigate = useNavigate();
  const { page } = useParams();
  const [openHelpDesk, setOpenHelpDesk] = useState(false);
  const [showSideMenu, setShowSideMenu] = useState(false);
  if (page === "exhibitor-services") {
    navigate("/exhibitor-services/0");
  }
  const { data, isLoading, isError, error } = useCommonGetInit(
    `${USER}/get-user?`
  );

  const onLogout = () => {
    deleteUser();
    navigate("/event/login");
  };
  useEffect(() => {
    if (isError) {
      if (error.message === "No token provided.") {
        navigate("/event/login");
      }
    }
  }, [isError, error]);
  const onMenuClick = () => {
    setShowSideMenu((prev) => !prev);
  };
  if (isLoading) {
    return <MuPb />;
  }
  if (!data) {
    return <Alert severity="error">No data loaded</Alert>;
  }
  const onHelpDeskPopup = () => {
    setOpenHelpDesk(true);
  };

  return (
    <div className="rd-expo-dash-c">
      <HelpdeskPopup open={openHelpDesk} setOpen={setOpenHelpDesk} />

      <NewDashHeader
        user={data}
        profileLink={`/exhibitor/profile`}
        onMenuClick={onMenuClick}
        onLogout={onLogout}
        notifications={[]}
        onHelpDeskPopup={onHelpDeskPopup}
        profileImg={
          data.profile_image === undefined || data.profile_image === ""
            ? DEFAULT_DP
            : data.profile_image
        }
      />
      <MuiDashHeader />
      <div className="rd-dashboard-c">
        <div style={{ display: "flex" }}>
          <SideMenuList
            onLogout={onLogout}
            menuList={EXHIBITOR_MENU_LIST}
            page={page}
          />
          <MobileSideMenuList
            showSideMenu={showSideMenu}
            setShowSideMenu={setShowSideMenu}
            onLogout={onLogout}
            category={EXHIBITOR}
            menuList={EXHIBITOR_MENU_LIST}
            page={page}
          />

          <div style={{ flex: 1, width: "100%" }}>
            {page === "dashboard" || page === undefined ? (
              <Dashboard user={data} />
            ) : page === "profile" ? (
              <ProfileDetails user={data} />
            ) : page === "explore" ||
              page === "exhibitors" ||
              page === "products" ||
              page === "ai-matching" ||
              page === "news" ||
              page === "visitors" ? (
              <Explore page={page} />
            ) : page === "floor-plan" ? (
              <GeneralView />
            ) : page === "exhibition-catalogue" ? (
              <ExhibitionCatalogue />
            ) : page === "all-meetings" ? (
              <AllMeetings user={data} />
            ) : page === "exhibitor-services" ? (
              <div></div>
            ) : page === "services" ? (
              <Services />
            ) : page === "my-schedule" ? (
              <MySchedule />
            ) : page === "favorites" ? (
              <MyFavorites />
            ) : page === "settings" ? (
              <Settings />
            ) : page === "onsite" ? (
              <Onsite />
            ) : page === "reports" ? (
              <Reports />
            ) : page === "view-profile" ? (
              <ProfileViewByID />
            ) : page === "product-view" ? (
              <ProductContainer />
            ) : page === "profile-viewers" ? (
              <ProfileViewers />
            ) : page === "profile-viewers" ? (
              <ProfileViewers />
            ) : page === "stall-viewers" ? (
              <MyStallViewers />
            ) : page === "chat" ? (
              <ChatApp />
            ) : page === "meeting-scheduler" ? (
              <ScheduleMeeting />
            ) : (
              <></>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default ExhibitorDashboard;
