import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Box, Avatar, useMediaQuery } from "@mui/material";
import ChatIcon from "@mui/icons-material/Chat";
import { useTheme } from "@mui/material/styles";
const MuiDashHeader = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box sx={{ p: 1 }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "white",
          color: "black",
          boxShadow:
            "0 3px 6px rgba(246, 245, 245, 0.16), 0 3px 6px rgba(112, 111, 111, 0.23)",
        }}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
            {isMobile ? (
              <IconButton edge="start" color="inherit" aria-label="menu">
                <MenuIcon />
              </IconButton>
            ) : (
              <img
                src="https://s3.ap-south-1.amazonaws.com/expoplanner.in/PMTX+2024+-+Logo.png"
                style={{ display: "block", height: "56px" }}
              />
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
            <IconButton color="inherit">
              <ChatIcon />
            </IconButton>
            <IconButton color="inherit">
              <NotificationsNoneIcon />
            </IconButton>
            <IconButton color="inherit" onClick={() => {}}>
              <Avatar
                alt="Profile Image"
                src="https://lh3.googleusercontent.com/a/ACg8ocJbAOiDUTRRX7lSz9On8cg8OXWurVXWkqYCNrJt3wGvObLUMLQ=s576-c-no"
              />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default MuiDashHeader;
