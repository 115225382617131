import React from "react";
import Exhibitors from "./Exhibitors";
import Products from "./Products";
import Matchmaking from "./Matchmaking";
import Visitors from "./Visitors";
import News from "./News";
const Explore = ({ page }) => {
  return (
    <div className="rd-dash-content">
      <div>
        {page === "explore" || page === "exhibitors" ? (
          <Exhibitors />
        ) : page === "products" ? (
          <Products />
        ) : page === "ai-matching" ? (
          <Matchmaking />
        ) : page === "visitors" ? (
          <Visitors />
        ) : (
          <News />
        )}
      </div>
    </div>
  );
};

export default Explore;
