import { useQuery } from "react-query";
import axios from "axios";
import { getCommonUrl } from "../../../utils2024/utils";
import { getHeader } from "../../../utility/utility";

const doAction = async (path) => {
  const url = getCommonUrl(path);
  try {
    const res = await axios.get(url, getHeader());
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useCommonGetInit = (path) => {
  return useQuery(["com-init-get", path], () => doAction(path), {
    staleTime: 1 * 60 * 1000,
    retry: 2,
  });
};

export default useCommonGetInit;
