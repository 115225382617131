import { useQuery } from "react-query";
import axios from "axios";

const doAction = async (path) => {
  try {
    const res = await axios.get(path);
    return res.data;
  } catch (error) {
    throw error.response.data;
  }
};

const useComPath = (path) => {
  return useQuery(["com-init-get", path], () => doAction(path), {
    staleTime: 0 * 60 * 1000,
    retry: 1,
  });
};

export default useComPath;
